import React from 'react';

import Layout from '../components/layout';

export default function Literature() {
      return (
            <Layout subject="Literature">
                  Literature
            </Layout>
      )
}